<template>
  <div v-if="batch && batch.shipment && batch.shipment.length > 0">
    <div class="columns">
      <div class="column has-text-centered">
        <div class="title is-2">
          <img
              src="../assets/logo_bw.png"
            alt="kiangkai"
            width="100"
            height="100"
            style="
              vertical-align: middle;
              position: absolute;
              top: 0.5em;
              left: 1em;
            "
          />
          ກຽງໄກ ຂົນສົ່ງດ່ວນ
        </div>
        <div>ວັນທີຝາກ: {{ batch.shipment[0].created_date | datetime }}</div>
        <div class="bigger-text">ເລກບິນ: {{ batchno }}</div>
        <div
          style="
            height: 40px;
            overflow: hidden;
            position: absolute;
            top: 1em;
            right: 1em;
          "
        >
          <vue-barcode
            :value="batchno"
            :height="70"
            :width="3"
            :displayValue="false"
            format="CODE128"
          />
        </div>
      </div>
    </div>
    <div class="table-container">
      <table class="table is-bordered is-fullwidth">
        <thead>
          <tr>
            <th rowspan="2">#</th>
            <th rowspan="2" class="has-text-centered">ລະຫັດ</th>
            <th rowspan="2" class="has-text-centered">ປາຍທາງ</th>
            <th rowspan="2" class="has-text-centered">ຂໍ້ມູນເຄື່ອງຝາກ</th>
            <th colspan="2" class="has-text-centered">ຄ່າຝາກ</th>
            <th colspan="2" class="has-text-centered">COD</th>
          </tr>
          <tr>
            <th class="has-text-centered">ກີບ</th>
            <th class="has-text-centered">ບາດ</th>
            <th class="has-text-centered">ກີບ</th>
            <th class="has-text-centered">ບາດ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in batch.shipment" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.code }}</td>
            <td>
              <p>
                ສາຂາ: {{ item.branch_branchToshipment_to_branch_id.name }}, ໂທ:
                {{ item.branch_branchToshipment_to_branch_id.tel }}
              </p>
              <p>ຜູ້ຮັບ: {{ item.receivername }}, ໂທ: {{ item.receivertel }}</p>
            </td>
            <td>
              <p>ປະເພດ: {{ item.parcel[0].parceltype.name }},</p>
              <p>
                ກວ້າງ: {{ item.parcel[0].width }}, ສູງ:
                {{ item.parcel[0].height }}, ຍາວ: {{ item.parcel[0].long }},
                ໜັກ: {{ item.parcel[0].weight }}
              </p>
            </td>
            <td class="has-text-right">{{ item.amount | formatnumber }}</td>
            <td class="has-text-right">{{ item.amount_thb | formatnumber }}</td>
            <td class="has-text-right">
              <p v-if="item.cod && item.cod[0]">
                {{ item.cod[0].amount | formatnumber }}
              </p>
            </td>
            <td class="has-text-right">
              <p v-if="item.cod && item.cod[1]">
                {{ item.cod[1].amount | formatnumber }}
              </p>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="4" class="has-text-right">ລວມ:</th>
            <th class="has-text-right">
              {{ _.sumBy(batch.shipment, "amount") | formatnumber }}
            </th>
            <th class="has-text-right">
              {{ _.sumBy(batch.shipment, "amount_thb") | formatnumber }}
            </th>
            <th class="has-text-right">
              {{ sumCod("LAK") | formatnumber }}
            </th>
            <th class="has-text-right">
              {{ sumCod("THB") | formatnumber }}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="columns">
      <div class="column has-text-centered">ພະນັກງານ</div>
      <div class="column has-text-centered">ລູກຄ້າ</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueBarcode from "vue-barcode";
export default {
  name: "PrintBatch",
  components: { VueBarcode },
  data() {
    return {
      batch: null,
    };
  },
  mounted() {
    this.batchno = this.$route.params["batchno"];
    this.get();
  },
  beforeMount() {
    window.onafterprint = () => {
      this.$router.go(-1);
    };
  },
  methods: {
    get() {
      axios.get(`shipments/getbatch/${this.batchno}`).then((res) => {
        console.log(res);
        this.batch = res;
        this.$nextTick(() => {
          setTimeout(() => {
            window.print();
          }, 1000);
        });
      });
    },
    sumCod(ccy) {
      const sum = { LAK: 0, THB: 0 };
      for (let index = 0; index < this.batch.shipment.length; index++) {
        const item = this.batch.shipment[index];
        if (item.is_cod) {
          for (let i = 0; i < item.cod.length; i++) {
            const cod = item.cod[i];
            console.log("c", cod);
            sum[cod.ccy] += cod.amount;
          }
        }
      }
      return sum[ccy];
    },
  },
};
</script>